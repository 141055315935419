<template>
    <div style="padding: 20px">
        <div class="flex justify-between">
            <el-form :inline="true" :model="searchForm">
                <el-form-item label="填表人/关键字">
                    <el-input v-model="searchForm.search" placeholder="填表人/关键字"></el-input>
                </el-form-item>
                <el-form-item label="选择日期">
                    <el-date-picker
                            v-model="searchForm.date"
                            type="date"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search()">查询</el-button>
                    <el-button type="primary" @click="exportMessage()">导出财务报表</el-button>
                </el-form-item>
            </el-form>
            <!-- 操作按钮 -->
            <el-form :inline="true">
                <el-form-item>
                    <el-button icon="el-icon-plus" @click="toEdit()">添加财务报表</el-button>
                    <!-- <el-button type="primary" icon="el-icon-edit" circle></el-button> -->
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="tableData" v-loading="loading">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="date" label="日期"></el-table-column>
            <el-table-column prop="completed_by" label="填表人"></el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
<!--            <el-table-column prop="hydropower" label="水电费"></el-table-column>-->
            <el-table-column label="基础成本">
                <el-table-column prop="rent" label="房租"></el-table-column>
                <el-table-column prop="water" label="水费"></el-table-column>
                <el-table-column prop="electric" label="电费"></el-table-column>
                <el-table-column prop="taxation" label="税费"></el-table-column>
                <el-table-column prop="fund" label="保值基金"></el-table-column>
                <el-table-column label="产品总成本" width="100">
                    <template slot-scope="scope">
                        {{scope.row.product_cost}}
                        <el-button @click="toDetails(scope.row)" size="mini">查看明细</el-button>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column  label="工资成本">
                <el-table-column prop="general_manager" label="总经理工资"></el-table-column>
                <el-table-column prop="shopowner" label="店长工资"></el-table-column>
                <el-table-column prop="sale" label="销售顾问工资"></el-table-column>
                <el-table-column prop="teacher" label="老师工资"></el-table-column>
                <el-table-column prop="logistics" label="后勤工资"></el-table-column>
                <el-table-column prop="wages_cost" label="工资总成本"></el-table-column>
            </el-table-column>
            <el-table-column label="其他费用">
                <el-table-column prop="phone_rate" label="电话费"></el-table-column>
                <el-table-column prop="work_rate" label="办公"></el-table-column>
                <el-table-column prop="consumables" label="耗材费"></el-table-column>
                <el-table-column prop="incidental" label="杂费"></el-table-column>
                <el-table-column prop="other" label="其他费用"></el-table-column>
            </el-table-column>
            <el-table-column label="实际营业额">
                <el-table-column prop="receivable" label="应收金额(现金业绩"></el-table-column>
                <el-table-column prop="net_receipts" label="实收金额(现金实收)"></el-table-column>
                <el-table-column prop="uncollected" label="未收金额(欠款)"></el-table-column>
                <el-table-column prop="actual_consumption" label="实耗占比"></el-table-column>
                <el-table-column prop="actual_revenue" label="实际营收额"></el-table-column>
                <el-table-column prop="actual_cost" label="实际营业额"></el-table-column>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                    <el-button @click="toEdit(scope.row)" size="mini">编辑</el-button>
                    <el-popconfirm title="确定删除吗？" @confirm="del(scope.row)">
                        <el-button slot="reference" size="mini">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="产品总成本明细" :visible.sync="dialogFormVisible" :modal="false">
            <div class="message">
                <div class="list flex" v-for="(item,index) in details" :key="index">
                    <span>{{index + 1}}</span>
                    <span>产品名:{{item.product_name}}</span>
                    <span>零售价:{{item.retail_price}}元</span>
                    <span>折扣 (百分比%):{{item.discount}}%</span>
                    <span>实际售价:{{item.actual_price}}元</span>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
            </div>
        </el-dialog>
        <div class="text-center margin-top">
            <el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="pageSizes" :page-size="searchForm.page_size"
                           layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="pageSizeChange">
            </el-pagination>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                loading: false, // 表格加载状态
                dialogFormVisible: false,
                changeTime:'',
                details:[],
                tableData: [], // 数据
                pageSizes: [],
                searchForm: {
                    search: '', // 否 string 用于搜索的关键字
                    date:'',
                    page: 1, // 否 int 页码
                    page_size: 10, // 否 int 页脚
                },
                total: 0,
            };
        },
        mounted() {
            // 查询数据
            this.search()
        },
        methods: {
            search() {
                this.loading = true;
                this.$api.table.financeList(this.searchForm).then(res => {
                    console.log(res)
                    this.tableData = res.data.data;
                    this.total = res.data.total;
                    this.pageSizes = res.data.per_page.toString().split()
                    this.searchForm.page_size = res.data.per_page
                    this.loading = false;
                })
            },
            pageSizeChange(size) {
                this.searchForm.page_size = size;
                this.search()
            },
            pageChange(page) {
                this.searchForm.page = page;
                this.search()
            },
            toEdit(item) {
                if (item) {
                    this.$router.push(`/tables/finance/edit/${item.id}`)
                } else {
                    this.$router.push(`/tables/finance/edit`)
                }
            },
            del(item) {
                const loading = this.$loading()
                this.$api.table.delFinance({
                    id: item.id
                }).then(res => {
                    loading.close()
                    if (res.code == 200) {
                        this.$message.success(`操作成功`);
                        this.search()
                    } else {
                        this.$message.error(res.msg);
                    }
                }).catch(err => {
                    loading.close()
                    this.$alert(err);
                });
            },
            toDetails(item){
                this.dialogFormVisible = true
                this.$api.table.financeDetails({
                    id: item.id
                }).then(res => {
                    this.details = res.data.product_cost_list
                })

            },
            exportMessage(){
                this.$api.table.financeExport({
                    search:this.searchForm.search,
                    date:this.searchForm.date,
                }).then(res => {
                    console.log(res)
                    let a = document.createElement('a');
                    //ArrayBuffer 转为 Blob
                    let blob = new Blob([res], {
                        type: "application/vnd.ms-excel"
                    });
                    let objectUrl = URL.createObjectURL(blob);
                    console.log(objectUrl)
                    a.setAttribute("href", objectUrl);
                    a.setAttribute("download", '财务报表.xls');
                    a.click();
                })
            }
        },
    };
</script>

<style scoped lang="scss">
    .cover {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 10px;
    }
    .message{
        .list{
            height: 50px;
            line-height: 50px;
            span{
                margin-right: 15px;
            }
        }
    }
</style>
